<template>
  <div id="loading" >
    <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
    </div>
</div>
</template>
<script>
import {animation} from "@/config/pluginInit";
export default {
  name: 'Loader',
  watch: {
    $route: function () {
      this.init()
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      const load = document.getElementById('loading')
      load.classList.remove('d-none')
      window.scrollTo({ top: 0, behavior: 'smooth' });
      animation.fadeIn(load, { duration: 20 })
      setTimeout (() => {
        animation.fadeOut(load, { duration: 20 })
        load.classList.add('d-none')
      }, 1000)
    }
  }
}
</script>
<style scoped lang="scss">
#loading {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #fff;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 4px;
    border-color: #f4f4f4;
    border-top-color: #57BC60;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>