<template>
    <div>
        <div class="iq-top-navbar">
            <div class="iq-navbar-custom">
                <nav class="navbar navbar-expand-lg navbar-light p-0">
                    <router-link :to="{ name: 'allorders' }" class="header-logo">
                        <img :src="logo" class="img-fluid" alt="logo">
                    </router-link>
                    <div class="d-flex align-items-center">
                        <b-navbar-toggle target="nav-collapse" class="navbar-toggler">
                            <i class="ri-menu-3-line"></i>
                        </b-navbar-toggle>
                        <b-collapse id="nav-collapse" is-nav>
                            <p class="client-name">{{ user.company_name }}</p>
                            <ul class="navbar-nav ml-auto navbar-list align-items-center">
                                <li class="nav-item nav-icon dropdown" v-nav-toggle>
                                    <a href="#" class="search-toggle dropdown-toggle btn border add-btn"
                                        id="dropdownMenuButton02" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        {{ activeStoreview.code }}
                                    </a>
                                    <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <div class="card shadow-none m-0">
                                            <div class="card-body p-3">
                                                <a class="iq-sub-card" v-for="storeview in user.store_views"
                                                    :key="storeview.id"
                                                    @click.prevent="() => updateStoreView(storeview)" to="#">{{ storeview.code }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="nav-item nav-icon search-content" v-nav-toggle>
                                    <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-search-line"></i>
                                    </a>
                                    <div class="iq-search-bar iq-sub-dropdown dropdown-menu"
                                        aria-labelledby="dropdownSearch">
                                        <form action="#" class="searchbox p-2">
                                            <div class="form-group mb-0 position-relative">
                                                <input type="text" class="text search-input font-size-12"
                                                    placeholder="type here to search...">
                                                <a href="#" class="search-link"><i class="las la-search"></i></a>
                                            </div>
                                        </form>
                                    </div>
                                </li>
                                <li class="nav-item nav-icon dropdown" v-nav-toggle>
                                    <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                                            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                        </svg>
                                        <span class="bg-primary "></span>
                                    </a>
                                    <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div class="card shadow-none m-0">
                                            <div class="card-body p-0 ">
                                                <div class="cust-title p-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <h5 class="mb-0">Notifications</h5>
                                                        <a class="badge badge-primary badge-card" href="#">0</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="nav-item nav-icon dropdown caption-content" v-nav-toggle>
                                    <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton4"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="user-icon">O</span>
                                    </a>
                                    <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div class="card shadow-none m-0">
                                            <div class="card-body p-0 text-center">
                                                <div class="p-3">
                                                    <h5 class="mb-1">{{ user.email }}</h5>
                                                    <p class="mb-0">{{ user.company_name }}</p>
                                                    <div class="d-flex align-items-center justify-content-center mt-3">
                                                        <btn @click="signOut" class="btn border">Sign Out</btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </b-collapse>
                    </div>
                </nav>
            </div>
        </div>
        <b-modal id="new-order" centered hide-footer hide-header hide-header-close>
            <div class="popup text-left">
                <h4 class="mb-3">Create Support Request</h4>
                <div class="content create-workform bg-body">
                    <div class="pb-3">
                        <label class="mb-2">Email</label>
                        <input type="text" class="form-control" placeholder="Enter Name or Email">
                    </div>
                    <div class="col-lg-12 mt-4">
                        <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                            <div class="btn btn-primary mr-4" data-dismiss="modal" @click="$bvModal.hide('new-order')">
                                Cancel</div>
                            <div class="btn btn-outline-primary" data-dismiss="modal"
                                @click="$bvModal.hide('new-order')">Create</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie';
import router from '../../../../router';
export default {
    name: "HeaderStyle1",

computed: {
    ...mapGetters({
        appName: 'appName',
        logo: 'logo',

        name: 'name',
        image1: 'image1',
        user: 'getUser',
        activeStoreview: 'getActiveStoreView'
    })
},
methods: {
     updateStoreView (storeView) {
      // let oldStoreView = activeStoreView.value;
      this.$store.commit('setActiveStoreView', storeView);
      Cookies.set('active_store_view', storeView.code);
      router.push({ name: 'allorders' });
    },
    signOut(){
     this.$store.dispatch('logOut');
    }
}

}
</script>
<style lang="scss">
.user-icon{
    width: 50px;
    height: 50px;
    background: #03102A;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.client-name{
    margin: 0;
    font-weight: 700;
    margin-right: 20px;
}
</style>