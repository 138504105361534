<template>
  <div class="iq-sidebar  sidebar-default ">
    <!-- <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
      <router-link :to="{name: 'layout.dashboard1'}" class="header-logo">
        <img :src="logo" class="img-fluid rounded-normal" alt="logo">
      </router-link>
      <div class="iq-menu-bt-sidebar">
        <i class="las la-bars wrapper-menu"></i>
      </div>
    </div> -->
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
        <nav class="iq-sidebar-menu pt-3">
            <ul id="iq-sidebar-toggle" class="iq-menu">
                <!-- <li :class="checkActive('layout.dashboard1') ? 'active' : ''">
                    <router-link :to="{name: 'layout.dashboard1'}" class="svg-icon">                        
                        <svg  class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                        </svg>
                        <span class="ml-4">Dashboards</span>
                    </router-link>
                </li> -->
                <li :class="checkActive('allorders') ? 'active' : ''">
                    <router-link :to="{name: 'allorders'}" class="svg-icon">                        
                        <svg class="svg-icon" id="p-dash5" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>
                        <span class="ml-4">Orders</span>
                    </router-link>
                </li>
                <!-- <li :class="checkActive('allproducts') ? 'active' : ''">
                    <router-link :to="{name: 'allproducts'}" class="svg-icon">                        
                        <svg class="svg-icon" id="p-dash5" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>
                        <span class="ml-4">Products</span>
                    </router-link>
                </li> -->
                
            </ul>
        </nav>
        <div class="p-3"></div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {core} from '@/config/pluginInit'
export default {
  name:"SidebarStyle",
  data () {
    return{
      product:['product.listproduct','product.addproduct'],
      sale:['sale.listsale','sale.addsale'],
      categories:[ 'category.listcategory','category.addcategory'],
      purchases:['purchase.listpurchases','purchase.addpurchase'],
      returns:['return.listreturns','return.addreturn'],
      people:['people.customers','people.addcustomers','people.users','people.addusers','people.suppliers','people.addsuppliers'],
      user:['app.user-profile','app.user-add','app.user-list'],
      ui:['Ui.avatars','Ui.alerts','Ui.badges','Ui.breadcrumbs','Ui.buttons','Ui.button-groups','Ui.boxshadows','Ui.colors','Ui.cards','Ui.carousels','Ui.grids','Ui.helper-classes','Ui.images','Ui.list-groups','Ui.media-objects','Ui.modals','Ui.notifications'
      ,'Ui.paginations','Ui.popovers','Ui.progressbars','Ui.typographys','Ui.tabs','Ui.tooltips','Ui.Embed-videos'],
      auth:['auth.login','auth.register','auth.recover-password','auth.confirm-mail','auth.lock-screen'],
      pricing:['price.pay','price.pay2'],
      error:['error.404','error.500'],
      form:['controls.form-checkbox','controls.form-layout','controls.form-input','controls.form-radio','controls.form-switch','controls.form-textarea','controls.form-validation',],
      table:['table.basic-table','table.data-table','table.edit-table',],
      pages:[]
    }
  },
  mounted () {
    core.SmoothScrollbar()
    core.changesidebar()
  },
   destroyed () {
    core.SmoothScrollbar()
    core.changesidebar()
  },
  computed : {
        ...mapGetters({
            appName: 'appName',
            logo:'logo'
        })
    },
    methods: {
    checkActive(route) {
        if (this.$route.name == route) {
            return true;
        }
        if (route.includes(this.$route.name)) {
            return true
        }
    }
}

}
</script>
